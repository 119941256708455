<template>
  <b-container class="py-4">
    <b-row class="justify-content-md-center mb-4">
      <h1 class="text-primary">Harmony House Bed Management System</h1>
    </b-row>
    
    <b-row class="justify-content-md-center">
      <b-alert
        :show="showBirthday"
        variant="primary"
        dismissible
        class="birthday-alert"
        @close="showBirthday = false"
      >
        <div v-for="(bday, index) in birthday" :key="index" class="alert-row">
          <i class="fas fa-birthday-cake mr-2"></i>
          <strong>Happy Birthday {{ bday.first_name }} {{ bday.last_name }}!</strong>
        </div>
      </b-alert>
    </b-row>

    <b-row class="justify-content-md-center">
      <b-card-group deck class="w-100 dashboard-cards">
        <CheckoutsCard />
        <EmptyBedsCard />
        <DailyCensusCard />
      </b-card-group>
    </b-row>
  </b-container>
</template>

<script>
import CheckoutsCard from "@/components/CheckoutsCard";
import EmptyBedsCard from "@/components/EmptyBedsCard";
import DailyCensusCard from "@/components/DailyCensusCard";
import axios from "axios";
export default {
  name: "Home",
  components: { EmptyBedsCard, CheckoutsCard, DailyCensusCard },
  data() {
    return {
      showBirthday: false,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      await axios
        .get("/api/hhms/guests/today_birthdays/")
        .then((response) => (this.birthday = response.data));
      if (this.birthday.length > 0) {
        this.showBirthday = true;
      }
    },
  },
};
</script>

<style scoped>
.birthday-alert {
  width: 100%;
  max-width: 600px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.alert-row {
  padding: 4px 0;
}

.dashboard-cards {
  margin-top: 2rem;
}

h1 {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

/* Add responsive styling */
@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }
  
  .dashboard-cards {
    margin-top: 1rem;
  }
}
</style>
