<template>
  <div class="guest-form-container">
    <b-container>
      <b-overlay :show="isLoading" rounded opacity="0.6" spinner-small spinner-variant="primary">
        <!-- Header Section -->
        <div class="d-flex justify-content-between align-items-center mb-4">
          <h2 class="form-title mb-0">Guest Information</h2>
          <b-button variant="outline-primary" href="/guest">
            <i class="fas fa-plus mr-2"></i>Add New Guest
          </b-button>
        </div>

        <!-- Alerts Section -->
        <b-alert
          :show="dismissCountDown"
          dismissible
          class="mt-3"
          variant="success"
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
        >
          <i class="fas fa-check-circle mr-2"></i>{{ alertMessage }}
        </b-alert>
        
        <b-alert v-model="showErrorAlert" dismissible class="mt-3" variant="danger">
          <i class="fas fa-exclamation-circle mr-2"></i>{{ errorMessage }}
        </b-alert>

        <!-- Confirmation Modal -->
        <b-modal
          id="confirm-update-modal"
          title="Confirm Update"
          header-bg-variant="primary"
          header-text-variant="light"
          ok-variant="primary"
          @ok="handleConfirmedUpdate"
          @cancel="handleCancelUpdate"
        >
          <p class="my-2">Are you sure you want to update this guest's information?</p>
        </b-modal>

        <!-- Main Form Card -->
        <b-card class="form-card">
          <b-form v-if="show" class="mb-3">
            <!-- Personal Information Section -->
            <div class="form-section mb-4">
              <h4 class="section-title mb-3">Personal Information</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group label="First Name:" label-class="form-label">
                    <b-form-input
                      v-model="formData.first_name"
                      :state="!formErrors.first_name"
                      placeholder="Enter first name"
                      class="form-input"
                    ></b-form-input>
                    <b-form-invalid-feedback v-if="formErrors.first_name">
                      {{ formErrors.first_name }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Last Name:" label-class="form-label">
                    <b-form-input
                      v-model="formData.last_name"
                      :state="!formErrors.last_name"
                      placeholder="Enter last name"
                      class="form-input"
                    ></b-form-input>
                    <b-form-invalid-feedback v-if="formErrors.last_name">
                      {{ formErrors.last_name }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- Continue with similar grouping for other fields -->
              <b-row>
                <b-col md="6">
                  <b-form-group label="Date of Birth:" label-class="form-label">
                    <b-form-input
                      type="date"
                      v-model="formData.date_of_birth"
                      class="form-input"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Email:" label-class="form-label">
                    <b-form-input
                      type="email"
                      v-model="formData.email"
                      :state="!formErrors.email"
                      placeholder="Enter email"
                      class="form-input"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <!-- Identification Section -->
            <div class="form-section mb-4">
              <h4 class="section-title mb-3">Identification</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group label="Social Security Number:" label-class="form-label">
                    <b-form-input
                      v-model="formData.social_security"
                      :state="!formErrors.social_security"
                      placeholder="Enter SSN"
                      class="form-input"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Phone Number:" label-class="form-label">
                    <b-form-input
                      type="tel"
                      v-model="formData.phone_number"
                      :state="!formErrors.phone_number"
                      placeholder="Enter phone number"
                      class="form-input"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group label="Driver License Number:" label-class="form-label">
                    <b-form-input
                      type="text"
                      v-model="formData.drivers_license"
                      placeholder="Enter license number"
                      class="form-input"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Driver License State:" label-class="form-label">
                    <b-form-input
                      type="text"
                      v-model="formData.drivers_license_state"
                      placeholder="Enter license state"
                      class="form-input"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <!-- Add this after the Identification Section and before the Status Section -->
            <div class="form-section mb-4">
              <h4 class="section-title mb-3">Vehicle Information</h4>
              <b-row>
                <b-col md="12" class="mb-3">
                  <b-form-group label-class="form-label">
                    <b-form-checkbox
                      v-model="formData.vehicle"
                      value="true"
                      unchecked-value="false"
                    >
                      Has Vehicle
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="formData.vehicle === 'true'">
                <b-col md="6">
                  <b-form-group label="Vehicle Make:" label-class="form-label">
                    <b-form-input
                      type="text"
                      v-model="formData.vehicle_make"
                      placeholder="Enter vehicle make"
                      class="form-input"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <!-- Commenting out vehicle model field for now
                <b-col md="6">
                  <b-form-group label="Vehicle Model:" label-class="form-label">
                    <b-form-input
                      type="text"
                      v-model="formData.vehicle_model"
                      placeholder="Enter vehicle model"
                      class="form-input"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                -->
              </b-row>
            </div>

            <!-- Status Section -->
            <div class="form-section mb-4">
              <h4 class="section-title mb-3">Status Information</h4>
              <b-row>
                <b-col md="4">
                  <b-form-group label-class="form-label">
                    <b-form-checkbox v-model="formData.veteran" value="true" unchecked-value="false">
                      Veteran Status
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label-class="form-label">
                    <b-form-checkbox v-model="formData.parolee" value="true" unchecked-value="false">
                      Parolee Status
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label-class="form-label">
                    <b-form-checkbox v-model="formData.sex_offender" value="true" unchecked-value="false">
                      Sex Offender Status
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <!-- Add this after the Status Section and before Form Actions -->
            <div class="form-section mb-4">
              <h4 class="section-title mb-3">Demographics</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group label="Race:" label-class="form-label">
                    <b-form-select
                      v-model="formData.race_demographic"
                      :options="Object.entries(raceOptions).map(([value, text]) => ({value, text}))"
                      class="form-input"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Income Range:" label-class="form-label">
                    <b-form-select
                      v-model="formData.income_range"
                      :options="Object.entries(incomeOptions).map(([value, text]) => ({value, text}))"
                      class="form-input"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <!-- Form Actions -->
            <div class="form-actions mt-4 text-center">
              <b-button-group>
                <b-button type="reset" variant="outline-danger" class="mr-2">
                  <i class="fas fa-undo mr-1"></i>Reset
                </b-button>
                <b-button
                  v-if="isEmptyObject() === true"
                  @click.prevent="createGuest()"
                  variant="success"
                >
                  <i class="fas fa-save mr-1"></i>Create
                </b-button>
                <b-button
                  v-else
                  @click.prevent="showUpdateConfirmation()"
                  variant="primary"
                >
                  <i class="fas fa-save mr-1"></i>Update
                </b-button>
              </b-button-group>
            </div>
          </b-form>
        </b-card>
      </b-overlay>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "GuestForm",
  data() {
    return {
      show: true,
      submitted: false,
      newGuest: false,
      dismissSecs: 5,
      dismissCountDown: 0,
      formErrors: {},
      raceOptions: {
        ASIAN: "Asian",
        BLACK: "Black",
        HISPANIC: "Hispanic",
        WHITE: "White",
        MULTIRACE: "Multirace",
        OTHER: "Other",
      },
      incomeOptions: {
        LOW: "$0",
        LOW_MID: "$16,050 (30%)",
        MID: "$26,750 (50%)",
        HIGH: "$42,750 (80%)",
        VERY_HIGH: "64,100 (120%)",
      },
      isLoading: false,
      alertMessage: '',
      errorMessage: '',
      showErrorAlert: false,
    };
  },
  props: {
    guest: Object,
  },
  computed: {
    formData: function () {
      return this.guest || {};
    },
  },
  methods: {
    validateForm() {
      this.formErrors = {};
      let isValid = true;

      // Validate first name
      if (!this.formData.first_name) {
        this.formErrors.first_name = 'First name is required';
        isValid = false;
      }

      // Validate last name
      if (!this.formData.last_name) {
        this.formErrors.last_name = 'Last name is required';
        isValid = false;
      }

      // Validate email format
      if (this.formData.email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(this.formData.email)) {
          this.formErrors.email = 'Please enter a valid email address';
          isValid = false;
        }
      }

      // Validate phone number format
      if (this.formData.phone_number) {
        const phoneValue = String(this.formData.phone_number);  // Convert to string
        const phoneRegex = /^\d{10}$/;
        if (!phoneRegex.test(phoneValue.replace(/\D/g, ''))) {
          this.formErrors.phone_number = 'Please enter a valid 10-digit phone number';
          isValid = false;
        }
      }

      // Validate SSN format
      if (this.formData.social_security) {
        const ssnValue = String(this.formData.social_security);  // Convert to string
        const ssnRegex = /^\d{9}$/;
        if (!ssnRegex.test(ssnValue.replace(/\D/g, ''))) {
          this.formErrors.social_security = 'Please enter a valid 9-digit SSN';
          isValid = false;
        }
      }

      // Validate date of birth
      if (this.formData.date_of_birth) {
        const dob = new Date(this.formData.date_of_birth);
        const today = new Date();
        if (dob > today) {
          this.formErrors.date_of_birth = 'Date of birth cannot be in the future';
          isValid = false;
        }
      }

      return isValid;
    },

    async onSubmit() {
      this.isLoading = true;
      try {
        const response = await axios.patch(
          "/api/hhms/guests/" + this.formData.id + "/",
          this.formData
        );
        this.formData = response.data;
        this.alertMessage = 'Guest information updated successfully!';
        this.showAlert();
      } catch (error) {
        if (error.response && error.response.data) {
          this.formErrors = error.response.data;
          this.errorMessage = 'Please correct the errors in the form.';
        } else {
          this.errorMessage = 'An error occurred while submitting the form';
        }
        this.showErrorAlert = true;
      } finally {
        this.isLoading = false;
      }
    },

    async createGuest() {
      if (!this.validateForm()) {
        return;
      }

      this.isLoading = true;
      try {
        const response = await axios.post("/api/hhms/guests/", this.formData);
        this.formData = response.data;
        if (response.status === 201) {
          this.alertMessage = 'New guest created successfully!';
          this.showAlert();
        }
      } catch (error) {
        if (error.response && error.response.data) {
          this.formErrors = error.response.data;
          this.errorMessage = 'Please correct the errors in the form.';
        } else {
          this.errorMessage = 'An error occurred while creating the guest';
        }
        this.showErrorAlert = true;
      } finally {
        this.isLoading = false;
      }
    },
    isEmptyObject() {
      if (typeof this.guest === "undefined" || this.guest === null) {
        return true;
      } else {
        console.log("initialized");
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    showUpdateConfirmation() {
      if (!this.validateForm()) {
        return;
      }
      this.$bvModal.show('confirm-update-modal');
    },
    handleConfirmedUpdate() {
      this.onSubmit();
    },
    handleCancelUpdate() {
      // Optional: Add any logic needed when update is cancelled
    },
  },
  async created() {
    if (this.guest) {
      this.isLoading = true;
      try {
        // If you need to fetch any initial data, do it here
        this.isLoading = false;
      } catch (error) {
        this.errorMessage = 'Error loading guest data';
        this.showErrorAlert = true;
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.guest-form-container {
  padding: 2rem 0;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.form-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
}

.form-title {
  color: #2c3e50;
  font-weight: 600;
}

.section-title {
  color: #3498db;
  font-size: 1.2rem;
  font-weight: 500;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #eee;
}

.form-section {
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.form-label {
  font-weight: 500;
  color: #2c3e50;
}

.form-input {
  border-radius: 4px;
  border: 1px solid #dce4ec;
  padding: 0.5rem;
  transition: border-color 0.15s ease-in-out;
}

.form-input:focus {
  border-color: #3498db;
  box-shadow: 0 0 0 0.2rem rgba(52, 152, 219, 0.25);
}

.form-actions {
  border-top: 1px solid #eee;
  padding-top: 1.5rem;
}

.btn {
  padding: 0.5rem 1.5rem;
  font-weight: 500;
  border-radius: 4px;
}

.btn-group {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Add smooth transitions */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
